<template>
  <div >

    <section class="hero is-medium">
      <div class="hero-body">
        <img>
        <p class="title" style="color: #429496">
          <img src="../assets/logo.png" alt="JoinedSystems logo" style="maxWidth:300px;maxHeight:300px;">

        </p>
      </div>
    </section>


    <section class="section">

        <div class="has-text-centered">

              <p>JoinedSystems e.U., Inhaber: Karl Hönninger</p>
              <p>Software Architektur, Software Entwicklung, IT Projektsupport</p>
              <p>UID-Nr: ATU60470911, FN: 441470x, Sitz: 3204 Kirchberg an der Pielach, Schloßgegend 29</p>
              <p>Tel: +43 650 4294060</p>
        </div>

    </section>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
